import React, { Suspense, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { renderRoutes, privateRenderRoutes } from "./config/routes";
import PrivateRoute from './PrivateRoute'
import { CheckPrivileges } from './PermissionContexts.js';
import "./common.scss";
import { Redirect } from 'react-router-dom';

const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let roleSuperAdmin = JSON.parse(localStorage.getItem("privilegesInfo"))?.is_super_admin ? true : null;
  let roleAdmin = JSON.parse(localStorage.getItem("privilegesInfo"))?.is_owner ? 1 : null;
  let is_employee = JSON.parse(localStorage.getItem("privilegesInfo"))?.is_employee;
  let employeePrivileges = JSON.parse(localStorage.getItem("privilegesInfo"))?.privileges;
  let portal_switch = JSON.parse(localStorage.getItem("privilegesInfo"))?.portal_switch;
  const superAdminRoutes = ['/dashboard', '/create-learning', '/roles-privileges', '/new-launch', '/release-notes']   // Routes that needs to be hided from admin

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    !loading && (
      <div className="App">
        <Suspense fallback={<div style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}>
        </div>
        }>
          <BrowserRouter>
            <Switch>
              {renderRoutes.map(([key, route]) => (
                <Route
                  key={key}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
              <CheckPrivileges>
                {privateRenderRoutes.map(([key, route]) => {
                  
                  if (roleAdmin && !roleSuperAdmin && (route.path === "/create-admin" || route.path === "/custom-course-creation")
                  ) {
                    return null;
                  } if (roleAdmin && roleSuperAdmin && superAdminRoutes.includes(route.path)) {
                    return null;
                  } 
                  if (portal_switch && route.path === "/dashboard") {
                    return <Redirect to="/home-user" />
                  }
                  // if (!roleAdmin && !roleSuperAdmin && is_employee && !employeePrivileges.includes(route.menuname)) {
                  //   return null;
                  // }    <----------------- Kindly review this
                  return (
                    <PrivateRoute
                      key={key}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  );
                })}
              </CheckPrivileges>
            </Switch>
          </BrowserRouter>
        </Suspense>
      </div>
    )
  );
}

export default App;